/* eslint-disable react/jsx-filename-extension */
import { Typography } from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="Not found" />
    <Typography variant="h6" component="h1">
      Page not found
    </Typography>
    <Link to="/">返回首页</Link>
  </Layout>
);

export default NotFoundPage;
